import type { IconProps } from "./SvgIcon";
import SvgIcon from "./SvgIcon";

export default function ChatBubbleOutlineIcon(props: IconProps) {
  return (
    <SvgIcon
      {...props}
      paths={
        <path
          fill="currentColor"
          d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z"
        ></path>
      }
    />
  );
}
