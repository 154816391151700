import ChatBubbleOutlineIcon from "@tamarack/ui/icons/ChatBubbleOutlineIcon";
import type { PropsWithChildren, ReactNode, RefObject } from "react";
import { forwardRef, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import CloseButton from "./CloseButton";
import CheckIcon from "./icons/CheckIcon";
import ErrorIcon from "./icons/ErrorIcon";
import WarningIcon from "./icons/WarningIcon";

export type AlertType = AlertProps["variant"];

export type AlertProps = PropsWithChildren & {
  action?: ReactNode;
  className?: string;
  iconOnly?: boolean;
  onClose?: (event: React.SyntheticEvent) => void;
  /**
   * Vertical alignment of the content
   *
   * @default center
   */
  valign?: "top" | "center" | "bottom";
  variant?: "success" | "info" | "warning" | "error";
  onClick?: (event: React.SyntheticEvent) => void;
};

export default forwardRef(function Alert(
  {
    action,
    children,
    className,
    iconOnly,
    onClick,
    onClose,
    valign = "center",
    variant = "success",
  }: AlertProps,
  ref
) {
  const Icon = useMemo(() => {
    switch (variant) {
      case "success":
        return CheckIcon;
      case "error":
        return ErrorIcon;
      case "info":
        return ChatBubbleOutlineIcon;
      case "warning":
        return WarningIcon;
    }
  }, [variant]);

  const closeButton =
    action ??
    (onClose ? (
      <CloseButton
        onClick={onClose}
        iconClassName={variant !== "warning" ? "text-white" : undefined}
      />
    ) : null);

  const Component = onClick ? "button" : "div";

  return (
    <Component
      onClick={onClick}
      role="alert"
      className={twMerge(
        "flex items-start justify-between rounded-lg border border-[rgba(0,0,0,0.12)] px-2 py-1 font-semibold",
        variant === "success"
          ? "bg-tm-success"
          : variant === "error"
            ? "bg-tm-error"
            : variant === "warning"
              ? "bg-tm-warning"
              : "bg-tm-info",
        variant !== "warning" ? "text-white" : undefined,
        onClick ? "active:shadow-md" : undefined,
        className
      )}
      ref={ref as RefObject<any>}
    >
      <div
        className={twMerge(
          "flex items-center gap-2 py-1",
          valign === "top" ? "items-start" : valign === "center" ? "items-center" : "items-end"
        )}
      >
        <span>
          <Icon size={24} />
        </span>

        {iconOnly ? null : <div>{children}</div>}
      </div>

      {closeButton ? <div className="ml-5 pt-1">{closeButton}</div> : null}
    </Component>
  );
});
